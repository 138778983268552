.image-gallery {
    width: 100%;
    height: auto;
    background: black;
}

.image-gallery-slide img {
    width: 100%;
    height: auto;
    max-height: 60vh;
    object-fit: contain;
    overflow: hidden;
    object-position: center center;
    background: black;
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
}

.video {
    width: 100%;
    height: auto;
    max-height: 70vh;
    object-fit: contain;
    overflow: hidden;
    object-position: center center;
    background: black;

}

.image-gallery-video {
    width: 100%;
    height: auto;
    max-height: 60vh;
    object-fit: contain;
    overflow: hidden;
    object-position: center center;
    background: black;
}

.video-react {
    display: block;
    vertical-align: top;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    background-color: #000;
    /* position: relative; */
    font-size: 10px;
    line-height: 1;
    font-family: serif, Times, "Times New Roman";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
@import url('https://fonts.googleapis.com/css?family=Lato:400,700');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.css');
@import './css/drawer.css';
@import './css/fuse-helpers.css';
@import '~antd/dist/antd.css';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
    margin: 0;
    padding: 0;
    font-family: 'Lato', sans-serif !important;
}

.cell-wrap-text {
    white-space: normal !important;
}

.m-r-10 {
    margin-right: 10px;
}

.slick-slide {
    height: 80vh !important;
}

.slick-slide img {
    min-height: 80vh !important;
}

.studentmanage-dialogcontent {
    padding: 0px !important;
}

button:focus {
    outline: 0px !important;
    outline: 0px auto -webkit-focus-ring-color !important;
}

.btn-link {
    font-weight: lighter !important;
}

.ag-cell-focus {
    border: none !important;
}


.demo-infinite-container {
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    overflow: auto;
    padding: 8px 24px;
    height: 550px;
    /* min-width: 350px; */
}

.card {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin: 10px;
}

.tableHeader {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    font-size: .875rem;
    margin-top: 30px;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    margin-bottom: 30px;
    border-radius: 6px;
    flex-direction: column;
}

.tableGrid.tableGrid1:not(.jss374) {
    padding: 15px;
    margin-top: -45px;
    border-radius: 3px;
}

.tableGrid1:not(.jss374) {
    background: #3f51b5;
    box-shadow: 0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2);
}

.tableGrid.tableGrid1 {
    color: #FFFFFF;
    margin: 0 15px;
    padding: 0;
    position: relative;
}

.tableGrid {
    display: flex;
    padding: 0.75rem 1.25rem;
    z-index: 3 !important;
    background: transparent;
    margin-bottom: 0;
    border-bottom: none;
}

.table {
    color: #FFFFFF;
    font: 700 15px/28px "Lato", sans-serif;
    text-transform: uppercase;
    min-height: auto;
    margin-bottom: 3px;
    text-decoration: none;
    margin: 10px 0px 0px 20px;
}

.jss265 {
    color: rgba(255, 255, 255, .62);
    margin: 0;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
}

.cursor_pointer {
    cursor: pointer;
}

.card-table.card-table1:not(.jss374) {
    /* padding: 5px; */
    margin-top: -36px;
    margin-left: 100px;
    border-radius: 3px;
}

.card-table.card-table1 {
    color: #FFFFFF;
    /* margin: 0px -4px; */
    padding: 0;
    /* position: relative; */
}

.card-table1:not(.jss374) {
    background: #3f51b5;
    box-shadow: 0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2);
}

.cardSubText {
    font-size: 30px !important;
    text-align: left;
}

.containerPadding {
    padding: 0px 10px 0px 10px;
    width: 100%;
}

.smallCardContainer {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
    margin: 0;
    box-sizing: border-box;
    padding: 0 15px !important;
}

.cardInnerLayout {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    font-size: .875rem;
    background: #fff;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    flex-direction: column;
}

.cardBody.cardBody2,
.cardBody.cardBody1,
.cardBody.cardBody3 {
    color: #FFFFFF;
    margin: 0 15px;
    padding: 0;
    position: relative;
}

.cardBody {
    padding: 0.75rem 1.25rem;
    z-index: 3 !important;
    background: transparent;
    margin-bottom: 0;
    border-bottom: none;
}

.cardTitle {
    float: right;
    padding: 15px;
    margin-top: -20px;
    margin-right: 15px;
    border-radius: 3px;
    font: 700 15px/28px "Lato", sans-serif;
    text-transform: uppercase;
    background: #3f51b5;
    box-shadow: 0 12px 20px -10px rgba(255, 152, 0, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(255, 152, 0, 0.2);
}

.cardNumber {
    color: #3C4858;
    margin-top: 0px;
    min-height: auto;
    font-weight: 300;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    margin-bottom: 3px;
    text-decoration: none;
    margin-top: 20px;
    font-size: 40px;
    text-align: left;
}

.cardFooter {
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    border-radius: 0;
    justify-content: space-between;
    background-color: transparent;
    border-top: 1px solid #eee;
    margin: 0 auto;
}

.cardFooterBody {
    color: #000;
    display: inline-flex;
    font-size: 12px;
}

.cardWidth {
    width: 100%;
}


.MuiInput-underline-261:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    /* transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
    border-bottom: none !important;
    pointer-events: none;
}

.MuiInput-underline-261:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: none !important;
    pointer-events: none;
}

.MuiInput-underline-279:before,
.MuiInput-underline-279:after {
    content: '' !important;
    border-bottom: none !important;
}

/*General Details*/
.cardtitleText div span {
    font-size: 16px;
    font-weight: bold;
    font-family: Helvetica, Arial, sans-serif;
}

.cardsubtitleText {
    font-size: 12px !important;
    font-weight: bold;
    font-family: 'Lato', sans-serif !important;
    color: #05B9A4 !important;
}

.cardComponent {
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.15) !important;
    border-radius: 12px !important;
}

.radioLabel {
    font-size: 10px !important;
    font-family: 'Lato', sans-serif !important;
}

.parentOpenLink {
    font-size: 12px !important;
    font-weight: bold !important;
    font-family: 'Lato', sans-serif !important;
    cursor: pointer;
    margin-left: 20px !important;
    color: #919191 !important;
}

.notifyParent {
    font-size: 16px !important;
    font-weight: bold;
    font-family: 'Lato', sans-serif !important;
    color: black !important;
    margin-left: -15px;
}

.stepper {
    background-color: #F2F2F2 !important;
    padding: 24px 24px !important;
}

.stepper svg {
    height: 50px !important;
    width: 50px !important;
    color: #3C3989 !important;
}

.stepperlink div:first-child {
    margin: 12px;
}

fieldset {
    border-radius: 0 !important;
}

.groupSelect {
    width: 100%;
}

.group {
    padding: 25px !important;
}

.eventDialogTitleRSVP {
    font-size: 24px !important;
    font-weight: bold !important;
    font-family: 'Lato', sans-serif !important;
    color: #3C3989 !important;
    text-align: right;
    margin-top: 30px !important;
}

.eventDialogTitle {
    font-size: 24px !important;
    font-weight: bold !important;
    font-family: 'Lato', sans-serif !important;
    color: #3C3989 !important;
    margin-top: 30px !important;
}

.eventDialogTitleMessage {
    font-size: 24px !important;
    font-weight: bold !important;
    font-family: 'Lato', sans-serif !important;
    color: #3C3989 !important;
    text-align: center;
    margin-top: 30px !important;
}

.eventDialogSubTitle {
    font-size: 20px !important;
    font-family: 'Lato', sans-serif !important;
    color: black !important;
    text-align: center;
    /*margin-top: 10px !important;*/
}

.meetingDialogTitle {
    font-size: 20px !important;
    font-weight: bold !important;
    font-family: 'Lato', sans-serif !important;
    color: #3C3989 !important;
    text-align: left;
    margin-top: 20px !important;
    margin-left: 10px !important;
}

.meetingDialogTitleTwo {
    font-size: 18px !important;
    font-weight: bold !important;
    font-family: 'Lato', sans-serif !important;
    color: #3C3989 !important;
    text-align: left;
    margin-top: 20px !important;
    margin-left: 10px !important;
}

.meetingDialogSubTitle {
    font-size: 18px !important;
    font-family: 'Lato', sans-serif !important;
    color: black !important;
    text-align: left;
    margin-left: 10px !important;
    margin-top: 15px !important;
}

.meetingDialogSummary {
    font-size: 22px !important;
    font-family: 'Lato', sans-serif !important;
    color: #05b9a4 !important;
    text-align: center;
    border: 8px solid;
    border-radius: 50%;
    width: 129px;
    padding: 22px;
    margin: 30px auto !important;
}

.assign {
    font-family: 'Lato', sans-serif !important;
    color: #05b9a4 !important;
    font-size: 14px !important;
    /* margin: 0px 5px !important; */
    font-weight: bold !important;
    cursor: pointer;
    /* padding: 20px 0px !important; */
    display: flex;
    align-items: center
}

.eventContainer {
    padding: 15px;
    border: 1px solid;
    margin: 20px auto !important;
    border-color: #707070 !important;
}

.eventDialogcontent {
    font-size: 14px !important;
    font-family: 'Lato', sans-serif !important;
    color: black !important;
}

.stickyAddEvent {
    position: sticky;
    bottom: 1rem;
    align-self: flex-start;
    float: right;
}


.note {
    font-size: 16px;
    color: #05B9A4 !important;
    font-family: 'Lato', sans-serif !important;
}

.formLabel {
    color: #05B9A4 !important;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Lato', sans-serif !important;
    margin-bottom: 10px !important;
}

.prevIcon {
    /* width: 60px !important; */
    /* height: 60px !important; */
    /* margin-bottom: 5px !important; */
    /* margin-right: 15px !important */
    float: left;
}

.button-float {
    width: 50px !important;
    height: 50px !important;
    /* margin-bottom: 5px !important; */
    /* margin-right: 15px !important */
    /* float: left; */
}

.prevIcon1 {
    /*width:34px !important;*/
    margin: 8px !important;
    float: left;
}

.prevContainer div:first-child {
    width: 100%;
    margin: 0 auto;
    /*margin-top: 225px !important;*/
}

.displayData {
    margin-top: 225px !important;
}

.displayTab {
    width: auto;
    margin-top: 50px !important;
}

.centerdiv {
    width: 100%;
    text-align: center;
    margin: 0 auto !important;
    z-index: 1;
}

.centerdatetime {
    width: 100%;
    margin: 0 auto !important;
    z-index: 1;
    position: relative;
}

.centerBottomDiv {
    width: 100%;
    margin: 0 auto !important;
    margin-top: -225px !important;
}

.centerContent {
    text-align: center;
}

.imagePrev {
    height: 199px;
    object-fit: cover;
    width: 50%;
}

.upload-btn-wrapper {
    /* position: absolute; */
    overflow: hidden;
    display: inline-block;
    /* margin-top: -30px; */
    /* margin-left: 20px; */
    width: 100%;
    /* top: 90%; */
    left: 0;
}

.upload-btn-wrapper .btn {
    color: #fff;
    background-color: #000;
    /* padding: 8px 20px; */
    border-radius: 0;
    font-size: 16px;
    /* font-weight: bold; */
    opacity: 0.6;
    width: 50%;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.overlay {
    /* height: 270px !important; */
    margin: 0px;
    position: relative;
    /*border-style: dashed;
    border-color: #d2d2d2;*/
}

.overlay-attachment {
    margin: 0px;
    position: relative;
    /*border-style: dashed;
    border-color: #d2d2d2;*/
}

.currentRSVP {
    font-size: 14px;
    color: #3c3989 !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
}

.pendingRSVP {
    font-size: 14px;
    color: #e33030 !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
}

.YES-2-Adults-2-children {
    width: 203px;
    height: 51px;
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3a3131;
}

.YES-2-Adults-2-children .text-style-1 {
    font-size: 14px;
    color: #05b9a4;
}

.rsvp {
    display: flex !important;
    flex-flow: row wrap !important;
    justify-content: space-between !important;
    margin-top: 15px !important;
    align-items: center !important;
}

.rsvp-around {
    display: flex !important;
    flex-flow: row wrap !important;
    justify-content: space-around !important;
    /* margin-top: 15px !important; */
}

.rsvp-evenly {
    display: flex !important;
    flex-flow: row wrap !important;
    justify-content: space-evenly !important;
    margin-top: 15px !important;
}

.yesbutton {
    border: 1px solid #05b9a4 !important;
    background-color: #FFFFFF !important;
    color: #05b9a4 !important;
    width: 90px;
    border-radius: 0px !important;
    margin-bottom: 10px !important;
}

.signupbutton {
    border: 1px solid rgba(118, 93, 177, 0.7) !important;
    background-color: rgba(118, 93, 177, 0.7) !important;
    color: #FFF !important;
    width: 90px;
    border-radius: 30px !important;
    position: relative;
}

.fullButton {
    border: 1px solid #8f8f9660 !important;
    background-color: #8f8f9660 !important;
    color: #FFF !important;
    width: 90px;
    border-radius: 30px !important;
    position: relative;
}

.editbutton {
    border: 1px solid #e11f66 !important;
    background-color: #FFFFFF !important;
    color: #e11f66 !important;
    width: 180px;
    border-radius: 0px !important;
    text-transform: capitalize;
}

.editbutton:hover {
    color: #fff !important;
    background-color: #e11f66 !important;
}

.editMeetingbutton {
    border: 1px solid #e11f66 !important;
    background-color: #FFFFFF !important;
    color: #e11f66 !important;
    width: 180px;
    border-radius: 0px !important;
    text-transform: capitalize;
    margin: 0 auto;
}

.editMeetingbutton:hover {
    color: #fff !important;
    background-color: #e11f66 !important;
}

.sendMsgbutton {
    border: 1px solid #3c3989 !important;
    background-color: #FFFFFF !important;
    color: #3c3989 !important;
    width: 150px;
    border-radius: 0px !important;
    text-transform: capitalize;
}

.sendMsgbutton:hover {
    color: #fff !important;
    background-color: #3c3989 !important;
}

.yesbutton:hover {
    color: #fff !important;
    background-color: #05b9a4 !important;
    margin-bottom: 10px !important;
}

.maybebutton {
    border: 1px solid #e38330 !important;
    background-color: #FFFFFF !important;
    color: #e38330 !important;
    width: 90px;
    border-radius: 0px !important;
    margin-bottom: 10px !important;
}

.maybebutton:hover {
    color: #fff !important;
    background-color: #e38330 !important;
    margin-bottom: 10px !important;
}

.nobutton {
    border: 1px solid #e11f66 !important;
    background-color: #FFFFFF !important;
    color: #e11f66 !important;
    width: 90px;
    margin-bottom: 10px !important;
    border-radius: 0px !important;
}

.nobutton:hover {
    color: #fff !important;
    background-color: #e11f66 !important;
    margin-bottom: 10px !important;
}

.box {
    display: flex;
    align-items: center;
}

.calendarContainer {
    height: 800px;
}

.loader {
    width: 50px;
    position: absolute;
    height: 50px;
    left: 23.5%;
    top: 33%;
}

.forSelectedgroup {
    font-size: 14px;
}

.previewTime {
    display: block;
}

.timeSectionIcon {
    left: 15%;
    position: relative;
}

.dateSectionIcon {
    left: 8%;
    position: relative;
}

.timeSection {
    margin: 0;
    float: left;
}

.eventIcon {
    width: 25px;
    height: 25px;
    margin: 2px 5px 2px 2px;
    border-radius: 50px;
}

.eventManage {
    /* float: right; */
    font-size: 12px;
    /* margin: auto 0; */
    /* margin-top: 7px; */
    padding: 5px 12px;
    background-color: #3c3989;
    color: #fff;
    font-weight: bold;
    width: 12%;
    margin-left: -75px;
    float: right;
    /* position: absolute; */
    /* right: 20px; */
    /* top: 36%; */
    cursor: pointer;
}

.errorText {
    color: red !important;
}

.titleDiv {
    display: inline-block;
    margin: 0 auto;
}

.locationSection {
    float: left;
    margin: 6px 0px;
    font-weight: bold;
    text-align: left;
    font-size: 18px;
}

.groupSection {
    position: relative;
    margin-top: 40px;
}

.groupChip {
    padding: 10px;
    border: 1px solid #3c3989;
    border-radius: 20px;
    margin: 10px;
    font-size: 14px;
    color: #3c3989;
}

.groupPopper {
    top: -9% !important;
}

.groupPopper div+div {
    overflow-x: inherit;
    overflow-y: inherit;
    padding-top: 12px;
}

.groupPopper div+div:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 15px #ffffff;
    border-left: solid 20px transparent;
    border-right: solid 20px transparent;
    -webkit-filter: drop-shadow(0px 4px 1px rgba(0, 0, 0, .5));
    filter: drop-shadow(0px 4px 1px rgba(0, 0, 0, 0.13));
}

.signupPopper {
    top: -160px !important;
    position: absolute;
}

.signupPopper div+div {
    overflow-x: inherit;
    overflow-y: inherit;
    padding-top: 12px;
}

.signupPopper div+div:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: solid 15px #ffffff;
    border-left: solid 20px transparent;
    border-right: solid 20px transparent;
    -webkit-filter: drop-shadow(0px 4px 1px rgba(0, 0, 0, .5));
    filter: drop-shadow(0px 4px 1px rgba(0, 0, 0, 0.13));
}

#confirmation-dialog-title {
    text-align: center;
}

.groupAvtar {
    margin: 10px auto 10px !important;
    padding: 25px;
    background-color: #3c3989 !important;
    font-size: 28px !important;
    font-family: "Helvetica", "Arial", sans-serif !important;
}

.groupAvatarLable {
    margin: 10px 20px !important;
    color: #3c3989 !important;
}

.avatarColor {
    background: #05b9a4 !important;
    padding: 20px;
    font-family: "Helvetica", "Arial", sans-serif !important;
    font-size: 18px !important;
}

.parentName span {
    color: #3c3989 !important;
    font-family: "Helvetica", "Arial", sans-serif !important;
    font-size: 18px !important;
}

.parentList {
    padding: 0px 20px !important;
}

.dialogContent {
    padding: 0;
    overflow: scroll;
    overflow-x: hidden;
}

.signup {
    padding: 12px;
    border: solid 1px #E1E1E1;
    margin: 10px 0px;
}

.invitation {
    padding: 12px;
    border: solid 1px #E1E1E1;
    margin: 10px 0px;
    cursor: pointer;
}


.invitationTitle {
    width: 161px;
    height: 20px;
    font-family: "Helvetica", "Arial", sans-serif !important;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #121208;
}

.invitationEdit {

    height: 14px;
    font-family: 'Lato', sans-serif !important;
    font-size: 12px !important;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #05b9a4;
    float: right;
}

.invitationSubtitle {
    font-size: 14px !important;
    font-family: 'Lato', sans-serif !important;
    color: black !important;
    /* text-align: center; */
    margin-top: 10px !important;
}

.titleIntend {
    width: 44px;
    height: 11px;
    font-family: 'Lato', sans-serif !important;
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #605656;
    margin-left: 10px;
}

.response {
    width: 44px;
    height: 11px;
    font-family: 'Lato', sans-serif !important;
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #121208;
}

.responseAns {
    width: 44px;
    height: 11px;
    font-family: 'Lato', sans-serif !important;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #118744;
    margin-left: 5px;
}

.responsePending {
    width: 44px;
    height: 11px;
    font-family: 'Lato', sans-serif !important;
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #e11f66;
    margin-left: 5px;
}

.invitationMore {
    width: 29px;
    height: 14px;
    font-family: 'Lato', sans-serif !important;
    font-size: 12px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #a7a7a7;
    float: right;
}

.customProgressbar {
    position: relative;
    height: 8px;
    width: 106px;
    /* border-radius: 50px; */
    border: 1px solid #c9c9c9;
}

.filler {
    background: #05b9a4;
    height: 100%;
    border-radius: inherit;
    transition: width .2s ease-in;
}

.filledText {
    width: 55px;
    height: 11px;
    font-family: 'Lato', sans-serif !important;
    font-size: 10px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #605656;
}

.rsvpContainer {
    margin: 45px 40px;
}

.rsvpAttend {
    font-size: 15px !important;
    font-family: 'Lato', sans-serif !important;
    color: black !important;
    text-align: center;
    margin: 25px !important;
    font-weight: bold !important;
}

.rsvpUpdate {
    font-size: 15px !important;
    font-family: 'Lato', sans-serif !important;
    color: #e1861f !important;
    text-align: center;
    margin: 25px !important;
    font-weight: bold !important;
    cursor: pointer;
}

.rsvpYesTitle {
    font-size: 16px !important;
    font-family: 'Lato', sans-serif !important;
    color: black !important;
    text-align: center;
    margin: 25px !important;
}

.button-section {
    padding: 0px 20px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
}

input[type=number] {
    width: 30%;
}

img {
    max-height: 280px;
    margin: 0 auto;
}

.app-container {
    width: 500px;
    margin: 30px auto;
}

.clear {
    clear: both;
}

.details,
.title {
    text-align: center;
}

.error {
    margin-top: 4px;
    color: red;
}

.has-error {
    border: 1px dotted red;
}

.image-container {
    align-items: center;
    display: flex;
    width: 85%;
    height: 80%;
    float: left;
    margin: 15px 10px 10px 37px;
    text-align: center;
}

.preview-container {
    height: 335px;
    width: 100%;
    margin-bottom: 10px;
}

.placeholder-preview,
.render-preview {
    text-align: center;
    background-color: #efebeb;
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.upload-container {
    cursor: pointer;
    height: 300px;
}

.previewSection {
    left: 0;
    /* position: absolute; */
    top: 15.5%;
    height: auto;
    overflow: hidden;
    /* flex: 1 1; */
    /* flex-direction: row; */
}

.previewImg ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.previewImg li {
    float: left;
}

.previewImg li a {
    display: block;
    color: white;
    text-align: center;
    padding: 16px;
    text-decoration: none;
}

.customRow {
    /* display: flex; */
}

.customRow div:first-child {
    width: auto;
    margin: 5px;
}

.customRow .prevContainer div:first-child {
    margin: 0px !important;
}

.customColumn {
    padding: 5px;
    display: inline-block;
}

.attachmentPrev {
    margin: 0px !important;
}

.attachmentPrev div {
    margin: 0px !important;
}


.prev-section-one {
    width: auto !important;
    margin: 0 !important;
}


.loader-container {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1200;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.mainloader {
    border: 4px solid #f3f3f3;
    /* Light grey */
    border-top: 4px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.slots {
    margin-top: 20px;
    padding: 0 50px;
    position: relative;
    justify-content: space-between;
}

.slots:after {
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.16);
    height: 4px;
    width: 140px;
    margin: 0 auto;
}

.renderLableTimePicker fieldset {
    border: none;
    cursor: pointer;
}

.renderLableTimePicker input {
    cursor: pointer;
}

.renderLableTimePicker input {
    padding: 0px !important;
}

#custom-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

#custom-scroll::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
    background-color: #F5F5F5;
}

#custom-scroll::-webkit-scrollbar-thumb {
    background-color: #b1abc896;
    border-radius: 10px;
}

.deleteIcon {
    margin-top: -7px !important;
}

.firstSlot:after {
    content: "";
    background-color: #000;
    position: absolute;
    width: 20px;
    height: 8px;
    top: 8px;
    right: -30px;
    display: block;
    border-radius: 30%;
}

.firstSlot {
    position: relative;
}

.fieldSpot div:before {
    border: none;
}

.fieldSpot div:after {
    border: none;
}

.fieldSpot input {
    background: #f5f5f5;
}

.fieldSelectSpot {
    background: #f5f5f5;
}

.fieldSelectSpot:before {
    border-bottom: 0px !important;
}

.fieldSelectSpot:after {
    border: 0px !important;
}

.fieldSpot .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
}

.fieldCommentSpot {
    position: relative;
    width: 100%;
}

.fieldCommentSpot div:before {
    border: none;
}

.fieldCommentSpot div:after {
    border: none;
}

.fieldCommentSpot input {
    background: #f5f5f5;
}

.fieldCommentSpot .MuiInput-underline:hover:not(.Mui-disabled):before {
    border: none;
}

.meetingEditButton {
    margin: 0 auto !important;
    display: block !important;
}

.roundTextBox {
    width: 100% !important;
    border-radius: 20px;
    padding: 5px;
}

.removeicon {
    cursor: pointer;
    position: relative;
    /* bottom: 50px; */
    /* left: 26px; */
    right: 10px;
    bottom: 3px;
}

.MuiSelect-icon {
    color: white !important;
}
.fileContainer .chooseFileButton {
    border-radius: 5px;
}

.fileContainer {
    align-items: flex-start;
}

fieldset {
    width: 100%;
}